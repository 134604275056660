.expand {
    @include z-index(expand);
    border-bottom: 1px solid $color-dark-blue;
    height: 0;
    width: 100%;
    //opacity: 0;
    overflow: hidden;
    padding-left: 66px;
    position: relative;



    @include breakpoint(not-desktop) {
        z-index: 1;
    }

    @include breakpoint(phone) {
        padding-left: 0;
    }



    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 67px;
        width: 1px;
        height: 100%;
        background: $color-dark-blue;

        @include breakpoint(phone) {
            width: 100%;
            height: 1px;
            left: 0;
        }
    }

    &--themes {
        @include breakpoint(phone) {
            border-bottom: none;
            height: calc(100vh - #{$header});
            &::before {
                display: none;
            }
        }
    }

    &--menu {
        @include breakpoint(phone) {
            &::before {
                display: none;
            }
        }
    }
}
