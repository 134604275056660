.header {
    @include z-index(header);
    @include flex(row, space-between, center);
    position: relative;
    height: $header;
    width: 100%;

    @include breakpoint(not-desktop) {
        position: static;
        justify-content: initial;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 43px;
        width: 100%;
        height: 1px;
        background-color: $color-dark-blue;
        z-index: 6;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -44px;
        left: 0;
        width: 100%;
        height: 130px;
        background: radial-gradient(circle, rgba(236,183,126,1) 0%, rgba(223,223,223,0) 90%);
        filter: blur(50px);
        opacity: 0;
        transition: opacity 1s ease-in-out;
        z-index: 2;
        pointer-events: none;
    }

    &.themes-open {
        @include breakpoint(desktop) {
            &::before {
                opacity: 0.5;
            }
        }
    }

    &.menu-open {
        &::before {
            opacity: 1;
        }

        @include breakpoint(desktop) {
            &::before {
                opacity: 0;
            }
        }
    }

    &__right {
        @include flex(row, center, center);
        @include font-size(34,41);

        @include breakpoint(not-desktop) {
            display: none;
        }
    }



    &__center {
        @include font-family('serif', 100);
        @include font-size(30,41);
        text-transform: uppercase;
        position: absolute;
        left: 50%;
        margin-left: -240px;
        z-index: 6;

        @include breakpoint(not-desktop) {
            @include flex(row, center, center);
            font-size: 4.6vw;
            position: static;
            margin: 0;
            height: 100%;
            width: 83%;
            border-left: 1px solid $color-dark-blue;
        }
    }



    &__left {
        @include flex(row, center, center);
        z-index: 6;

    }



    &__hamburger {
        position: relative;
        width: 39px;
        height: 13px;
        border: 14px solid transparent;

        @include breakpoint(phone) {
            height: 10px;
        }

        &:hover {
            cursor: pointer;

            @include breakpoint(desktop) {
                &::before {
                    width: 90%;
                }

                &::after {
                    width: 50%;
                }
            }
        }

        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            height: 1px;
            width: 100%;
            background: $color-dark-blue;
            transform-origin: 50% 50%;
            transition: transform 0.3s, top 0.3s, bottom 0.3s, width 0.3s;

        }

        &::before {
            top: 0;

            .header.menu-open & {
                top: 6px;
                width: 100%;
                transform: rotate(45deg);

                @include breakpoint(phone) {
                    top: 5px;
                    width: 80%;
                    left: 6px;
                }
            }
        }

        &::after {
            bottom: 0;

            .header.menu-open & {
                bottom: 6px;
                width: 100%;
                transform: rotate(-45deg);

                @include breakpoint(phone) {
                    bottom: 4px;
                    width: 80%;
                    left: 6px;
                }
            }
        }

        &:hover {
            .header.menu-open & {
                transform: rotate(-180deg);
                transition: transform 0.3s;
            }
        }
    }



    &__themes {
        box-sizing: border-box;
        position: relative;
        margin-left: 9px;
        transition: opacity 0.3s ease-out;

        @include breakpoint(tablet) {
            padding-right: 25px;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: -20px;
            right: 0;
            width: 150px;
            height: 150px;
            background: radial-gradient(circle, rgba(164,203,211,1) 0%, rgba(223,223,223,1) 100%);
            background-repeat: no-repeat;
            background-position: 50px 60px;
            filter: blur(20px);
            pointer-events: none;

            @include breakpoint(desktop) {
                display: none;
            }
        }

        button {
            @include font-size(20, 20);
            letter-spacing: -0.02px;
            width: 130px;
            height: 26px;
            border-radius: 11px;
            background: transparent;
            border: 1px solid $color-dark-blue;
            white-space: nowrap;

            @include breakpoint(not-desktop) {
                position: relative;
                @include font-family(sans, 300);
                @include font-size(25, 20);
                height: 38px;
                width: 275px;
                border-radius: 14px;
                opacity: 1;
                transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out, left 0.3s ease-in-out;
            }

            @include breakpoint(phone-small) {
                width: 250px;
            }

            @include breakpoint(tablet) {
                height: 50px;
                border-radius: 19px;
                width: 40vw;
            }

            &:first-of-type {

                @include breakpoint(tablet) {
                    position: absolute;
                    left: 50%;
                    margin-left: -20vw;
                }

                article.news-open & {
                    opacity: 0;
                    pointer-events: none;
                }

                @include breakpoint(desktop) {
                    &:hover img{
                        filter: invert(100%);
                    }
                }


                img {
                    position: relative;
                    padding: 0 5px 4px 0;
                    transform-origin: 50% 50%;
                    left: 0;
                    top: 0;
                    transition: transform 0.2s ease-in-out, left 0.2s ease-in-out, top 0.2s ease-in-out, filter 0.2s ease-in-out;

                    @include breakpoint(not-desktop) {
                        position: relative;
                        width: 13px;
                        height: 13px;
                        left: -24%;
                        top: -2px;
                        transform: rotate(180deg);

                        .header.themes-open &{
                            transform: rotate(0deg);
                            top: 2px;
                            left: -22%;
                        }
                    }

                    @include breakpoint(desktop) {

                        .header.themes-open &{
                            transform: rotate(180deg);
                            left: -5px;
                            top: -3px;
                        }
                    }
                }
            }

            article.is-blurred & {
                filter: blur(10px);
            }


            &:hover {
                cursor: pointer;
            }


            &:last-of-type {

                @include breakpoint(desktop) {
                    display: none;
                }

                @include breakpoint(tablet) {
                    width: 50px;
                }

                width: 38px;
                padding: 0;
                margin-left: 6px;
                left: 0;

                img {
                    position: relative;
                    padding: 0;
                    left: 0;
                    top: -1px;
                    height: 17px;
                    width: auto;
                }

                article.news-open &{
                    left: vw(40px);
                    pointer-events: all !important;

                    @include breakpoint(tablet) {
                        left: 0;
                    }

                    @include breakpoint(phone-small) {
                        left: 0;
                    }
                }
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: -9px;
            top: -10px;
            height: 44px;
            width: 1px;
            background-color: $color-dark-blue;

            @include breakpoint(not-desktop) {
                display: none;
            }
        }

        @include breakpoint(not-desktop) {
            @include flex(row, center, center);
            margin: 0;
            position: absolute;
            bottom: 20px;
            left: 0;
            width: 100%;

            article.news-open &{
                pointer-events: none;
            }
        }

        @include breakpoint(tablet) {
            @include flex(row, flex-end, center);
        }

        &.invisible {
            opacity: 0;
            pointer-events: none;
        }
    }



    &__date {
        @include font-family('sans', 300);
        width: 120px;
        text-align: center;
        position: relative;
        padding: 0 3px 2px 3px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: -2px;
            height: 44px;
            width: 1px;
            background-color: $color-dark-blue;
        }
    }



    &__day {
        @include font-family('sans', 700);
        @include font-size(30, 41);
        position: relative;
        padding: 0 12px;

        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            top: -2px;
            height: 44px;
            width: 1px;
            background: $color-dark-blue;
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }
    }


    &__hour {
        @include font-family('sans', 300);
        @include font-size(15, 15);
        text-align: left;
        width: 40px;
        padding: 0 3px 0 9px;
    }
}

article.is-blurred {
    .header.themes-open {
        button {
            filter: blur(0);
        }
    }
}