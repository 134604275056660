
$size-hd: 1600px;
$size-desktop: 1025px;
$size-tablet: 660px;
$size-min: 320px;

$size-laptop-height: 640px;

$margin: 20px;
$mobile-margin: 28px;
$tablet-margin: $mobile-margin * 3;

$spacer: 20px;

$header: 44px;

$module-width: 606px;
$left-space: 77px;
