.tooltip {
  position: fixed;
  pointer-events: none;
  opacity: 0;

  &:hover {
    .arr {
      transform: translate(3px, -3px);
    }
  }

  &__wrap {
    position: relative;
    transform: translate(-50%, calc(-100% - 93px));
    top: 20px;
    // width: 246px;
    width: 286px;
    padding: 9px 11px;
    background-color: $color-tooltip;
    border-bottom: 1px solid $color-black;
    @include flex(row, flex-start, flex-start);
    pointer-events: all;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 50%;
    //   transform: translate(-50%, 50%);
    //   background-color: $color-black;
    //   width: 9px;
    //   height: 9px;
    //   border-radius: 50px;
    // }

    .bottom-align & {
      transform: translate(-50%, calc(-100% + 135px));
      border-bottom: 0;
      border-top: 1px solid $color-black;
      top: 10px;
    }
  }

  &__dot {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: $color-black;
    width: 9px;
    height: 9px;
    border-radius: 50px;

    .bottom-align & {
      bottom: auto;
      top: 0;
      transform: translate(-50%, -50%);
    }
  }

  &__texts {
    flex: 85%;
  }

  &__linkicon {
    flex: 15%;
    height: 100%;
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 85%;
      width: 1px;
      height: 100%;
      background-color: $color-black;
      pointer-events: none;
    }

    svg {
      width: 21px;
      transform: translate(0, -3px);
      max-width: 100%;
      padding-left: 22px;
      padding-right: 3px;
    }

    .arr {
      transition: transform $time-fast $ease-custom;
      transform: translate(0, 0);
    }
  }

  &__pointer {
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 50px;
    background-color: $color-white;
    transform: translate(-50%, -50%);
  }

  &__curve {
    pointer-events: none;
    position: absolute;
    top: -89px;
    left: -88px;
    width: 100px;
    height: 100px;
    border: 1px solid $color-black;
    border-color: transparent $color-black transparent transparent;
    border-radius: 0 50px 50px 0;

    .bottom-align & {
      top: -17px;
    }
  }

  .title {
    margin: 0;
    font-family: $font-serif;
    @include font-size(14, 17);
  }

  .location {
    margin: 0;
    font-family: $font-sans;
    @include font-size(11, 16);
    margin-top: 3px;
  }

  .link {
    @include full;
  }
}
