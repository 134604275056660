
.loader {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 0;
    @include z-index(loader);
    background: linear-gradient(180deg, #E7EAEB 0%, #D8DDE0 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    color: $color-text;

    @include breakpoint(phone) {
        height: var(--app-height);
        padding-bottom: $header;
    }


    &__globe {
        position: relative;
        width: 100%;
        margin: 55px auto auto;

        @include breakpoint(tablet) {
            width: 60%;
        }

        @include breakpoint(desktop) {
            width: vw(308);
        }

        img {
            width: 100%;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -180px;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgb(0,0,0);
            background: radial-gradient(circle, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 40%);
            transform: scaleY(.3);
        }
    }


    &__title {
        position: relative;
        top: vw(50px);
        text-align: center;
        @include font-size(46, 46);
        @include font-family("serif");
        text-transform: uppercase;
        font-weight: 100;
        padding-bottom: 5px;

        @include breakpoint(phone) {
            width: 90%;
            @include font-size(38,38)
        }

        @include breakpoint(phone-small) {
            @include font-size(34,38)
        }

        span {
            @include font-size(29,31);

            @include breakpoint(phone) {
                @include font-size(28,40)
            }
        }

        .line {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: $color-dark-blue;

            &--top {
                top: 0;
            }

            &--bottom {
                bottom: 0;
            }
        }
    }



    &__footer {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 31px;

        @include breakpoint(phone) {
            @include flex(column, center, center);
            width: 90%;
            padding: 0 0 vw(100px) 0;
        }

        @include breakpoint(tablet) {
            margin-bottom: 40px;
        }

        &--text {
            box-sizing: border-box;
            @include font-size(27, 41);
            @include font-family("sans", 100);
            margin: 0 28px 0 0;
            white-space: nowrap;
            min-width: 210px;

            @include breakpoint(phone) {
                min-width: unset;
                margin: 0 0 15px 0;
                @include font-size(24, 20);
            }
        }



        &--line {
            width: 100%;
            height: 1px;
            background-color: $color-dark-blue;
        }



        img,
        svg {
            position: relative;
            top: 10px;
            height: 50px;
            margin-left: 15px;
            width: auto;

            @include breakpoint(phone) {
                align-self: flex-end;
                height: 38px;
                margin: 0 -10px 0 0;
            }
        }

        svg {
            margin-left: 24px;
            height: 40px;
        }
    }
}
