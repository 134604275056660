.sources {
    width: 90%;
    // padding: 0 vw(35px) 0 15px;
    padding: 0 16px;
    height: fit-content;
    @include z-index(about);
    max-width: 600px;

    @include breakpoint(not-desktop) {
        overflow: scroll;
        max-width: unset;
        height: calc(100vh - #{$header});
        width: 100vw;
        padding: 0 vw(35px) 0 15px;
        padding-left: vw(100px);
        background: linear-gradient(180deg, #E7EAEB 0%, #D8DDE0 100%);
    }



    .content {
        padding-bottom: 0;

        @include breakpoint(not-desktop) {
            margin-bottom: vw(100px);
        }
    }


    .title {
        @include font-size(68, 41);
        @include font-family(serif, 100);
        margin: 30px 0;
        margin-bottom: 70px;
        @include breakpoint(desktop) {
            margin-top: 48px;
        }
    }
}