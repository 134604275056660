.visually-hidden,
%visually-hidden {
    position: absolute;
    overflow: hidden;
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: none;
    clip: rect(0 0 0 0);
}

// screen reader text
.sr-only,
.screen-reader-text {
    @extend %visually-hidden;
}
