.news {
    position: relative;
    width: $module-width;
    padding: 13px;
    padding-left: 15px;
    pointer-events: all;

    @include breakpoint(desktop) {
        overflow: hidden;
        padding: 0 16px;
    }

    @include breakpoint(not-desktop) {
        height: 100vh;
        overflow-y: scroll;
    }

    @include breakpoint(phone) {
        position: absolute;
        padding: 0 20px;
        max-width: calc(100vw);
        min-height: 100%;
        left: 100%;
        @include z-index(news-mobile);
        background: linear-gradient(180deg, #e7eaeb 0%, #d8dde0 100%);
        box-sizing: border-box;
        padding-bottom: vh(100px);
    }

    @include breakpoint(tablet) {
        padding: vw(70px);
        box-sizing: border-box;
        position: relative;
        width: 100vw;
        left: 100vw;
        @include z-index(news-mobile);
        background: linear-gradient(180deg, #e7eaeb 0%, #d8dde0 100%);
    }

    &__wrapper {
        margin-bottom: 100px;
    }

    &__title {

        h2 {
            @include font-size(68, 41);
            @include font-family(serif, 100);
            margin: 0 0 20px 0;
            padding-top: 20px;

            @include breakpoint(phone) {
                @include font-size(36, 39);
                margin-left: 0;
                margin-bottom: 9px;
            }

            &::first-letter {
                text-transform: uppercase;
            }
        }

        h2 + button {
            margin-top: 6px;
        }
    }

    &__button {
        width: 150px;
        height: 21px;
        background: transparent;
        border: 1px solid $color-dark-blue;
        border-radius: 9px;
        padding-top: 1px;
        margin-left: 0px;
        margin-top: 16px;
        visibility: hidden;

        @include breakpoint(phone) {
            margin-top: 15px;
        }

        &:hover {
            cursor: pointer;
        }

        &.is-visible {
            visibility: visible;
        }
    }

    &__gradient {
        position: absolute;
        top: -55%;
        left: -85px;
        width: 100px;
        height: 200%;
        z-index: -1;

        @include breakpoint(not-desktop) {
            display: none;
        }
    }

    &__item {
        position: relative;
        padding: 5px 0 5px 0px;
        left: 0;
        margin-bottom: 10px;
        border-bottom: 1px dashed $color-navy;
        cursor: pointer;

        .link {
            @include full;
            z-index: 1;
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
        }

        @include breakpoint(phone) {
            @include font-size(19, 22);
        }

        @each $label, $color in $category-colors {
            &--#{$label} {
                @include breakpoint(desktop) {
                    .news__top {
                        font-weight: 300;
                    }

                    .news__gradient {
                        background: $color;
                        background-position-x: left;
                        background-repeat: no-repeat;
                        transform-origin: left;
                        transform: scale(0.5) translateX(-100%) translateZ(0);
                        transition: transform 0.3s $ease-in-out;
                        padding-right: 48px;
                    }

                    &:hover {
                        // .news__top {
                        //     font-weight: 700;
                        // }

                        .news__gradient {
                            transform: scale(1) translateX(-24px) translateZ(0);
                        }
                    }
                }
            }
        }
    }

    &__top {
        @include font-size(24, 25);
        @include font-family(serif, 300);
    }

    &__bottom {
        @include flex(row, space-between);
        @include font-size(14, 16);
        @include font-family(sans, 400);
        margin: 6px 0;

        @include breakpoint(phone) {
            @include font-size(11, 12);
        }

        .image {
            width: 30px;
        }
    }

    &__info {
        @include flex(row, flex-start, center);
        padding: 21px 0 15px 0;
        margin-bottom: 10px;
        border-bottom: 1px solid $color-dark-blue;

        @include breakpoint(phone) {
            padding-top: 0;
            border-bottom: none;
        }

        &--item {
            @include flex(column, center, flex-start);
            @include font-size(34, 41);
            @include font-family(sans, 700);
            width: auto;
            height: 50px;
            padding-left: 15px;
            padding-right: 20px;
            border-left: 1px solid $color-dark-blue;

            @include breakpoint(phone) {
                @include font-size(24, 29);
                @include flex(column, center, center);
                text-align: center;
                padding: 0 7px;

                &:last-of-type {
                    border-right: 1px solid $color-dark-blue;
                }
            }

            &:first-of-type {
                padding-left: 0;
                border: none;

                @include breakpoint(phone) {
                    padding-left: 7px;
                    border-left: 1px solid $color-dark-blue;
                }
            }

            span {
                @include font-size(15, 16);
                @include font-family(sans, 400);
                text-transform: uppercase;

                @include breakpoint(phone) {
                    margin-top: vw(10px);
                    @include font-size(11, 12);
                    text-align: center;
                }
            }
        }
    }
}
