.section {
    @include z-index(content);
    @include flex(row, flex-start, flex-start);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    top: -2px;
    width: calc(100% - 68px);
    padding-left: 68px;
    transition: filter 0.3s ease-in-out;



    &--main {
        height: var(--app-height);
        scroll-behavior: smooth;

        @include breakpoint(not-desktop) {
            overflow: hidden;
        }
    }



    article.is-blurred &{
        filter: blur(10px);
    }

    @include breakpoint(not-desktop) {
        height: var(--app-height);
        position: absolute;
        top: $header;
        width: 100%;
        padding: 0;
        z-index: 0;
    }



    &__lines {
        position: fixed;
        height: 100%;

        @include breakpoint(not-desktop) {
            display: none;
        }

        &::after, &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            height: 100%;
            width: 1px;
            background: $color-dark-blue;
        }

        &::after {
            left: -1px;
        }

        &::before {
            left: 643px;
        }


        @include breakpoint(desktop) {
            &::before {
                visibility: hidden;
            }
        }
    }



    &--intro {

        overflow: hidden;
    }



    &__gradient {
        position: fixed;
        bottom: -100px;
        left: 50%;
        margin-left: -30vw;
        width: 60vw;
        height: 150px;
        filter: blur(130px);

        @include breakpoint(not-desktop) {
            display: none;
        }
    }
}
