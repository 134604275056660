:root {
    --app-height: 100%;
}

html,
body {
    min-height: 100%;
    height: 100%;
    overflow: hidden;
    color: $color-black;

    background: linear-gradient(180deg, #E7EAEB 0%, #D8DDE0 100%);
}

button:not(.link) {
    position: relative;
    color: $color-black;
    overflow: hidden;
    transition: color 0.2s ease-in-out;

    @include breakpoint(desktop) {
        &:hover {
            color: $color-theme;

            &::before {
                transform: scaleY(1);
            }

            border-color: $color-black;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-black;
            transition: transform 0.2s ease-in-out;
            transform-origin: bottom;
            transform: scaleY(0);
            z-index: -1;
        }
    }
}

// debug dat gui
.dg.ac {
    z-index: 100 !important;
    display: none;
}


body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
    display: none;
}
