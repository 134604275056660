.globe {
    @include z-index(globe);
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @include breakpoint(phone) {
        top: -20px;
    }



    &__wrapper {
        position: sticky;
        top: 0;
        flex-grow: 1;
        width: 100%;
        height: 100%;

        @include breakpoint(not-desktop) {
            position: absolute;
            top: 0;
            max-height: 100vh;
        }

        @include breakpoint(desktop) {
            border-left: 1px solid $color-black;
        }
    }
}
