.menu {

    @include breakpoint(not-desktop) {
        position: absolute;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        @include breakpoint(not-desktop) {
            background: linear-gradient(180deg, #E7EAEB 0%, #D8DDE0 100%);
        }
    }



    &__list {
        height: vw(423px);
        margin: 0;
        list-style: none;
        padding: 20px 0 0 0;
        z-index: 10;



        @include breakpoint(not-desktop) {
            height: auto;
            padding: 20px 0;
        }



        li {

            margin: 1px 0 0 7px;
            width: fit-content;

            &:first-of-type {
                margin-top: 0;
            }

        }

        a {
            @include font-size(43, 43);
            @include font-family('serif', 100);
            display: block;
            color: inherit;
            text-decoration: none;
            text-transform: uppercase;
            border: 1px solid transparent;
            border-radius: 24px;
            padding: 0 13px;
            padding-top: 3px;

            &:hover {
                cursor: pointer;
                border-color: $color-dark-blue;
            }

            &.active {
                cursor: default;
                border-color: $color-dark-blue;
            }
        }

    }



    &__footer {

        box-sizing: border-box;
        @include flex(row, space-between, center);
        padding: 0 10px 12px 0;
        width: calc(100% - 66px);

        a {
            opacity: 1;
            transition: opacity $time-fast $ease-custom;

            &:hover {
                opacity: .6;
            }
        }

        @include breakpoint(not-desktop) {
            @include flex(column-reverse, center, flex-start);
            width: 100%;
            padding-bottom: 0;
        }

        &--left {
            padding-left: 16px;
            @include font-size(15, 16);

            @include breakpoint(not-desktop) {
                @include flex(row, space-between, center);
                @include font-size(12,12);
                width: 100%;
            }

            @include breakpoint(tablet) {
                width: calc(100vw - 82px);
            }

            img {
                width: 135px;
                height: 44px;
                padding: 0 10px 8px 0;

                @include breakpoint(desktop) {
                    display: none;
                }
            }

            a {
                position: relative;
                color: currentColor;
                text-decoration: none;

                // &::after {
                //     content: "";
                //     position: absolute;
                //     bottom: -3px;
                //     left: 0;
                //     width: 100%;
                //     height: 1px;
                //     background-color: currentColor;
                //     transform: scaleX(0);
                //     transition: $time transform $ease-custom;
                //     transform-origin: 100% 50%;
                // }

                // &:hover {
                //     &::after {
                //         transform: scaleX(1);
                //         transform-origin: 0% 50%;
                //     }
                // }
            }
        }

        &--right {
            @include flex(row, space-between, center);
            height: 16px;

            @include breakpoint(not-desktop) {
                @include flex(column, center, center);
                height: auto;
                padding-left: 11px;
                margin-top: 6px;
                margin-bottom: vh(100px);
            }

            @include breakpoint(tablet) {
                margin: 0;
            }

            img {
                height: 16px;
                margin: 0 10px;

                @include breakpoint(not-desktop) {
                    margin: 10px;
                    height: 13px;
                }
            }
        }
    }
}