.themes {

    @include breakpoint(not-desktop) {
        background: linear-gradient(180deg, #E7EAEB 0%, #D8DDE0 100%);
    }

    &__title {
        @include font-size(68, 41);
        @include font-family(serif, 100);
        margin: 31px 0 26px 9px;

        @include breakpoint(phone) {
            display: none;
        }

        @include breakpoint(desktop) {
            margin-left: 16px;
        }

        @media (min-width: 1600px) {
            margin-left: 24px;
        }
    }



    &__list {
        width: calc(85% - 66px);
        padding: 0 8px;

        @include breakpoint(phone) {
            padding-top: 20px;
            width: 100%;
        }

        @include breakpoint(desktop) {
            padding: 0 12px;
        }

        @media (min-width: 1600px) {
            padding: 16px;
        }
    }



    &__button {
        cursor: pointer;
        @include font-family(sans, 300);
        @include font-size(28, 20);
        height: 36px;
        width: auto;
        border-radius: 18px;
        border: 1px solid $color-dark-blue;
        background: transparent;
        margin: 4px;
        padding: 0 13px;

        @include breakpoint(phone) {
            @include font-size(21,20);
            height: 33px;
            padding: 0 10px;
            margin: 4px;
        }

        &.is-active {
            background: $color-black;
            color: $color-theme;
            border-color: $color-black;
        }
    }
}