.content {
    @include font-family(serif, 100);
    @include font-size(25, 28);
    padding-bottom: 50px;

    @include breakpoint(not-desktop) {
        margin-bottom: vh(250px);
    }

    @include breakpoint(phone) {
        max-width: 90%;
    }

    span {
        @include font-family(sans, 700);
        @include font-size(25, 45);

        @include breakpoint(not-desktop) {
            line-height: 60px;
        }
    }

    a {
        position: relative;
        color: inherit;
        text-decoration: none;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentColor;
        }

        @include breakpoint(desktop) {
            &:hover {
                &::after {
                    animation: lineDraw $time ease-in-out;
                }
            }
        }
    }
}

@keyframes lineDraw {
    0% {
        transform: scaleX(1);
        transform-origin: 100% 50%;
    }
    50% {
        transform: scaleX(0);
        transform-origin: 100% 50%;
    }
    51% {
        transform: scaleX(0);
        transform-origin: 0% 50%;
    }
    100% {
        transform: scaleX(1);
        transform-origin: 0% 50%;
    }
}
