@import "style/variables/colors";
@import "style/variables/fonts";
@import "style/variables/sizes";
@import "style/variables/transitions";
@import "style/variables/zindex";

@import "style/mixins";
@import "style/breakpoints";
@import "style/fonts";
@import "style/base";
@import "style/helpers";

@import "style/scaffold/loader";
@import "style/scaffold/header";
@import "style/scaffold/menu";
@import "style/scaffold/themes";
@import "style/scaffold/expand";
@import "style/scaffold/globe";
@import "style/scaffold/tooltip";
@import "style/scaffold/home";

@import "style/module/section";

@import "style/components/news";
@import "style/components/sources";
@import "style/components/about";
@import "style/components/content";

body {
    margin: 0;
    @include font-family("sans");
    color: $color-text;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
