$color-white: #fff;
$color-black: #000;
$color-pale: #fefefe;
$color-dark: #1f232b;
$color-dark-blue: rgba(17, 12, 69, 0.5);
$color-gray: #5b5f69;
$color-navy: #110c45;
$color-tooltip: #eef1f3;
$color-theme: #d6dadb;

$color-red: #ff7474;

$color-bg: $color-pale;
$color-text: $color-black;

$category-colors: (
    conflict: radial-gradient(circle, rgba(159, 156, 150, 0.3) 0%, rgba(223, 223, 223, 0) 65%),
    construction: radial-gradient(circle, rgba(80, 123, 111, 0.5) 0%, rgba(223, 223, 223, 0) 65%),
    energy: radial-gradient(circle, rgba(248, 103, 63, 0.5) 0%, rgba(223, 223, 223, 0) 65%),
    wildlands: radial-gradient(circle, rgba(121, 116, 255, 0.5) 0%, rgba(223, 223, 223, 0) 65%),
    pollution: radial-gradient(circle, rgba(169, 143, 98, 0.3) 0%, rgba(223, 223, 223, 0) 65%),
    fire: radial-gradient(circle, rgba(255, 116, 116, 0.3) 0%, rgba(223, 223, 223, 0) 65%),
    flood: radial-gradient(circle, rgba(206, 206, 126, 0.5) 0%, rgba(223, 223, 223, 0) 65%),
    glaciers: radial-gradient(circle, rgba(126, 178, 206, 0.5) 0%, rgba(223, 223, 223, 0) 65%),
    oceans: radial-gradient(circle, rgba(126, 193, 206, 0.5) 0%, rgba(223, 223, 223, 0) 65%),
    water: radial-gradient(circle, rgba(52, 60, 198, 0.3) 0%, rgba(223, 223, 223, 0) 65%),
    climate_crisis: radial-gradient(circle, rgba(207, 158, 158, 0.3) 0%, rgba(223, 223, 223, 0) 65%),
);
