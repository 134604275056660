.home {
    width: 100%;
    // padding-right: calc(15px + 2.1875vw);
    // max-width: 600px;
    padding: 0 vw(24px);
    padding-top: 18px;

    @include breakpoint(not-desktop) {
        width: 100vw;
        height: 100%;
        position: relative;
        padding: 0;
    }

    @media (min-width: 1600px) {
        // padding: 0 vw(24px);
        padding-top: 24px;
    }

    @include breakpoint(desktop) {
        width: fit-content;
        padding: 0;
        padding-top: 24px;
    }
}
